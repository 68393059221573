import "./App.css";
import "./external.css";
import "./custom.css";
import "./responsive.css";
import "./responsiveCustom.css";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { lazy, Suspense, useEffect, useState } from "react";
import MainApp from "./MainApp";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import SEO from "./Pages/helper/SEO";
import ApiRequest from "./Pages/helper/ApiRequest";
import { ApiEndpoints } from "./Pages/helper/ApiEndpoints";
import DemoButton from "./Pages/demoBtn/DemoButton";


const LandingPage = lazy(() => import("./Pages/landingPage/LandingPage"));

function App() {
  const [seoData, setSeoData] = useState(null);
  const seoListEndpoint = ApiEndpoints.seo.seoList;
  const location = useLocation();
  const landingPageUrl = location.pathname

  // Fetching SEO data based on current URL
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", seoListEndpoint);
        const result = response.seos;
        // console.log(result);
        const currentPath = window.location.pathname;
        // Use pathname instead of full URL
        // console.log(currentPath , "currentPath")
        // const seoForCurrentPage = result.find((seo) => seo.url === currentPath);
        const seoForCurrentPage = result.find(
          (seo) => new URL(seo.url).pathname === currentPath
        );
        // console.log(seoForCurrentPage, ">>>>>")
        setSeoData(seoForCurrentPage);
        // console.log("seoData>>>>>", seoData )// Handle the case when no SEO data is found
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };
    dataFetch();

  }, [location.pathname]);



  if (landingPageUrl.includes("/white-label-taxi-dispatch-software-app")) {
    return (
      <>
        <Suspense fallback={
          <>
            {/* <div className="loader-screen">
                <img src="/assets/home/loading-logo.webp" alt="logo" className="img-responsive" />
              </div> */}
            <div className="loader-only-css">
              <div className="loader-inner-css">
                <div></div>
              </div>
            </div>
          </>
        }></Suspense>
        <div className="app">
          <HelmetProvider>
            <SEO seo={seoData} />
            <DemoButton />
            <Routes>
              <Route path="/white-label-taxi-dispatch-software-app" element={<LandingPage />} />
            </Routes>
          </HelmetProvider>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="app">
        <MainApp />
      </div>
    </>
  );
}

export default App;
